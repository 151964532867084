import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import Banner from './components/Banner'

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))

function App() {
  const showRegister = process.env.REACT_APP_REGISTER === 'true';
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Banner />
        <Switch>
          <Route path="/login" component={Login} />
          {/* Conditionally render the create-account route */}
          {showRegister && <Route path="/create-account" component={CreateAccount} />}
          <Route path="/forgot-password" component={ForgotPassword} />

          {/* Place new routes over this */}
          <Route path="/app" component={Layout} />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect exact from="/" to="/login" />
        </Switch>
      </Router>
    </>
  )
}

export default App
